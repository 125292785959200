export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_ITEM_CART = "REMOVE_ITEM_CART";
export const UPDATE_CART_QUANTITY = "UPDATE_CART_QUANTITY";
export const CLEAR_CART = "CLEAR_CART";

export const UPDATE_DELIVERY_INFO = "UPDATE_DELIVERY_INFO";
export const SAVE_DELIVERY_INFO = "SAVE_DELIVERY_INFO";

export const SET_RESTAURANT_ID = "SET_RESTAURANT_ID";

//Action types