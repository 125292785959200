//Creating a order list
export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL";

//My order list
export const MY_ORDER_REQUEST = "MY_ORDER_REQUEST";
export const MY_ORDER_SUCCESS = "MY_ORDER_SUCCESS";
export const MY_ORDER_FAIL = "MY_ORDER_FAIL";

//My Order details
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";